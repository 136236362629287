import React, { useContext, useEffect, useState } from "react";
import { Colorcontext } from "../../Component/Colorcontext/ColorContext";
import searchicon from "../../Images/searchicon.png";
import searchresilutfilter from "../../Images/searchresilutfilter.png";
import logoownblm from "../../Images/logoownblm.png";
import logootherblm from "../../Images/logootherblm.png";
import logiiaihsui from "../../Images/logiiaihsui.png";
import Neoologoasjji from "../../Images/Neoologoasjji.png";
import lofogoeyyy from "../../Images/lofogoeyyy.png";
import paiisiidjuudug from "../../Images/paiisiidjuudug.png";
import askokdjjlobogo from "../../Images/askokdjjlobogo.png";
import imahggsercggdpremiy from "../../Images/imahggsercggdpremiy.png";
import prijjdhjjjjkj from "../../Images/prijjdhjjjjkj.png";
import { Link } from "react-router-dom";
import facebook_icon from "../../Images/facebook_icon.png";
import insta_icon from "../../Images/insta_icon.png";
import linkedin_icon from "../../Images/linkedin_icon.png";
import Ownpagesearchkeywordmodal from "../../Modal/Ownpagesearchkeywordmodal";
import { useNavigate, useLocation } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import close from "../../Images/close.png";
import { useDispatch } from "react-redux";
import { getSingleUserProfile } from "../../Redux/Slice/PostSlice";

const Blmemberlist = () => {
  const colordata = useContext(Colorcontext);
  const dispatch = useDispatch();
  const [ownpagesearchkeay, setownpagesearchkeay] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [company, setCompany] = useState([]);
  const [country, setCountry] = useState([]);
  const [designation, setDesignation] = useState([]);
  const { data, searchText } = location.state;
  const [searchedData, setSearchedData] = useState(data ?? []);
  const [text, setText] = useState(searchText ?? "");
  const [singleData, setSingleData] = useState();

  useEffect(() => {
    colordata.setcolor(true);
    return () => {
      colordata.setcolor(false);
    };
  }, []);

  useEffect(() => {
    getCompany();
    getCountry();
    getDesignation();
  }, []);

  const getCountryName = (_id) => {
    const countryName = country.find((countr) => countr._id === _id);
    return countryName ? countryName.name : "United Arab Emirates";
  };

  const getDesignationName = (_id) => {
    const designationName = designation.find(
      (designation) => designation._id === _id
    );
    console.log("designationName :", designationName);
    return designationName ? designationName.designation : "";
  };

  const getCompanyName = (_id) => {
    const companyName = company.find((company) => company._id === _id);
    return companyName ? companyName.type : "";
  };

  const getCompany = async () => {
    const response = await HttpClient.requestData("companyType", "GET", "");
    if (response.status) {
      setCompany(response.data);
    } else {
      console.log("Error while fetching company data");
    }
  };

  const getCountry = async () => {
    const response = await HttpClient.requestData("countries-list", "GET", "");
    if (response.status) {
      console.log("name111 : ", response.data);

      setCountry(response.data);
    } else {
      console.log("Error while fetching country data");
    }
  };

  const getDesignation = async () => {
    const response = await HttpClient.requestData("designation", "GET", "");
    if (response.status) {
      setDesignation(response.data);
    } else {
      console.log("Error while fetching designation data");
    }
  };

  console.log("SearchedData : ", JSON.stringify(searchedData));

  //   const searchresult = [

  //     {
  //       imaage: logoownblm,
  //       headepara: "Eagle star furniture - Advertising & Marketing",
  //       headsubpara:
  //         "Digital marketing l Advertising l Content production l Digital bu...",
  //       headsupsubpara:
  //         "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,",
  //     },
  //     {
  //       imaage: Neoologoasjji,
  //       headepara: "Neoo Search FZE - Advertising & Marketing",
  //       headsubpara:
  //         "Digital marketing l Advertising l Content production l Digital bu...",
  //       headsupsubpara:
  //         "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,",
  //     },
  //     {
  //       imaage: logootherblm,
  //       headepara: "Planet kids gallery - Publishing ",
  //       headsubpara:
  //         "Digital marketing l Advertising l Content production l Digital bu...",
  //       headsupsubpara:
  //         "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,",
  //     },
  //     {
  //       imaage: logiiaihsui,
  //       headepara: "Eagle star furniture - Advertising & Marketing",
  //       headsubpara:
  //         "Digital marketing l Advertising l Content production l Digital bu...",
  //       headsupsubpara:
  //         "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,",
  //     },
  //     {
  //       imaage: lofogoeyyy,
  //       headepara: "Eagle star furniture - Advertising & Marketing",
  //       headsubpara:
  //         "Digital marketing l Advertising l Content production l Digital bu...",
  //       headsupsubpara:
  //         "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,",
  //     },
  //     {
  //       imaage: paiisiidjuudug,
  //       headepara: "Planet kids gallery - Publishing",
  //       headsubpara:
  //         "Digital marketing l Advertising l Content production l Digital bu...",
  //       headsupsubpara:
  //         "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,",
  //     },
  //     {
  //       imaage: askokdjjlobogo,
  //       headepara: "Ocean infolab - Digital Marketing",
  //       headsubpara:
  //         "Digital marketing l Advertising l Content production l Digital bu...",
  //       headsupsubpara:
  //         "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,",
  //     },
  //     {
  //       imaage: logoownblm,
  //       headepara: "Eagle star furniture - Advertising & Marketing",
  //       headsubpara:
  //         "Digital marketing l Advertising l Content production l Digital bu...",
  //       headsupsubpara:
  //         "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,",
  //     },
  //     {
  //       imaage: Neoologoasjji,
  //       headepara: "Neoo Search FZE - Advertising & Marketing",
  //       headsubpara:
  //         "Digital marketing l Advertising l Content production l Digital bu...",
  //       headsupsubpara:
  //         "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,",
  //     },
  //     {
  //       imaage: logootherblm,
  //       headepara: "Planet kids gallery - Publishing ",
  //       headsubpara:
  //         "Digital marketing l Advertising l Content production l Digital bu...",
  //       headsupsubpara:
  //         "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,",
  //     },
  //     {
  //       imaage: logiiaihsui,
  //       headepara: "Eagle star furniture - Advertising & Marketing",
  //       headsubpara:
  //         "Digital marketing l Advertising l Content production l Digital bu...",
  //       headsupsubpara:
  //         "Neoo Search is a business network and online directory platform for small and medium-sized businesses. This platform is a one-stop marketing solution to identify and do business with SME's and verified companies,",
  //     },
  //   ];
  const searchName = async () => {
    if (text) {
      let data = {
        searchName: text,
      };
      const response = await HttpClient.requestData(
        "search-business",
        "POST",
        data
      );
      if (response?.status) {
        console.log(response?.data);
        setSearchedData(response?.data);
      } else {
        console.log("Error while fetching the data ");
      }
    }
  };
  const ownpagesearchkeyclcik = () => {
    setownpagesearchkeay(!ownpagesearchkeay);
  };
  const handleownsecrh = () => {
    navigate("/ownsearchresult");
  };

  const handleViewProfile = (UserID) => {
    // dispatch(getSingleUserProfile(UserID));
  };

  return (
    <>
      <section className="ownseacrhresultpagemain">
        <div className="cust_container">
          <div className="searchhresultandfilterdivflx">
            <div className="searfchresultleftdiv">
              <input
                type="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="searfchresultleftdivmain"
                placeholder=""
              />
              {text && (
                <div
                  className="searchiconimgnew"
                  style={{
                    position: "absolute",
                    top: "25%",
                    right: "7%",
                    cursor: "pointer",
                  }}
                  onClick={() => setText("")}
                >
                  <img
                    style={{
                      height: "15px",
                      width: "15px",
                    }}
                    src={close}
                    alt="closing button"
                  />
                </div>
              )}
              <div
                style={{
                  top: "10px",
                }}
                className="sectgresulticon"
                onClick={searchName}
              >
                <img src={searchicon} alt="..." />
              </div>
            </div>
            <div className="searchfilterdiv">
              <div
                className="searchfilterdivmain"
                onClick={ownpagesearchkeyclcik}
              >
                <div className="sercghghfilyerimg">
                  <img src={searchresilutfilter} alt="..." />
                </div>
              </div>
            </div>
          </div>

          <div className="ownseacrgresultpagedivbg">
            <div className="ownseacrgresultpagedivflx">
              <div className="ownseacrgresultpageleft">
                <p className="avoiaresrrstrtxppp">
                  About {searchedData.length} results
                </p>
                {searchedData.length === 0 ? (
                  <div className="serchresutllogodetaildivflx">
                    <p className="avoiaresrrstrtxppp">No results found</p>
                  </div>
                ) : (
                  searchedData.map((item, index) => {
                    return (
                      <div
                        className="serchresutllogodetaildivflx"
                        key={index}
                        onClick={() => setSingleData(item)}
                      >
                        <div className="serachresultlogoidiv">
                          <img src={item?.businessProfilePic} alt="..." />
                        </div>
                        <div className="serachresultdetailsdiv">
                          <h4 className="serctedheadrparatxt">
                            {item?.companyName} -{" "}
                            {getCompanyName(item?.companyTypeID)}
                          </h4>
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {item?.businessProductCat?.map((it, index) => (
                              <p
                                className="diisjadfebnclkmdgragjksopjkl"
                                key={index}
                                style={{
                                  marginLeft: "2px",
                                }}
                              >
                                {it} |
                              </p>
                            ))}
                          </div>

                          <p className="serchrelusubtparatxtmm">
                            {item.aboutBusiness}
                          </p>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
              {singleData ? (
                <div className="ownseacrgresultpageright">
                  <p className="prPremiumofiletxtp">Premium profile</p>
                  <div className="ownseacrgresultpagerightbrdr">
                    <div
                      className="ownseacrgresultpagerightbrdrtop"
                      // onClick={handleotherserchresult}
                    >
                      <div className="ownseacrgresultpagerreslppimg">
                        <img src={singleData?.banner} alt="..." />
                      </div>

                      <div className="logostrdetyskksldovkkdpo">
                        <div className="logostretdijjkkkljujuo">
                          <img src={singleData?.businessProfilePic} alt="..." />
                        </div>
                        <div className="allttehdiioskjetyapasedoib">
                          <p className="neosertsttfzrrtty">
                            {singleData?.companyName}
                          </p>
                          <p className="neosertsttfzrrttyadfd">
                            {getCompanyName(singleData?.companyTypeID)}
                          </p>
                          <div className="dofleoodiistartdivflx">
                            <p className="neosertsttfzrrttyadfdfff">
                              {/* {singleData?.} */}
                            </p>

                            <div className="statrrdibvggshhghuju">
                              <i class="fa-regular fa-star"></i>
                              <i class="fa-regular fa-star"></i>
                              <i class="fa-regular fa-star"></i>
                              <i class="fa-regular fa-star"></i>
                              <i class="fa-regular fa-star"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ownseacrgresultpagerightbrdrtopbottom">
                      <div className="">
                        <p className="tttxtyaplattsyggjjkllkkk">
                          {singleData?.location}
                        </p>
                        <p className="tttxtyaplattsyggjjkllkkk">
                          {singleData?.emirate},{" "}
                          {getCountryName(singleData?.country)}
                        </p>

                        <p className="phokennsjjsnyunnrbtk">
                          Phone: {singleData?.mobileNo}
                        </p>

                        <Link className="serttxtghsytxoppjkjo" to="/">
                          {singleData?.webAddress}
                        </Link>

                        <p className="opperpoodshheyxgbpppn">{}</p>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {singleData?.businessProductCat?.map((it, index) => (
                            <p
                              className="diisjadfebnclkmdgragjksopjkl"
                              key={index}
                              style={{
                                marginLeft: "2px",
                              }}
                            >
                              {it} |
                            </p>
                          ))}
                        </div>

                        <div className="viewprofileimgaedivvhjjnflx">
                          <div className="prijjdhjjjjkjaaimg">
                            <img src={singleData?.profilePhoto} alt="..." />
                          </div>
                          <div
                            onClick={handleViewProfile(singleData?._id)}
                            className="bvhhjjapsidhbfbbrkjk"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            View profile
                          </div>
                        </div>

                        <div className="">
                          <p className="sclloidjjdttxppphjo">Social links</p>
                          <div className="socijjjsjkjijnndnkdobvbbhflx">
                            <Link
                              to={singleData?.facebookLink}
                              className="socijjjsjkjijnndnk"
                            >
                              <img src={facebook_icon} alt="..." />
                            </Link>
                            <Link
                              to={singleData?.instaLink}
                              className="socijjjsjkjijnndnk"
                            >
                              <img src={insta_icon} alt="..." />
                            </Link>
                            <Link
                              to={singleData?.linkedInLink}
                              className="socijjjsjkjijnndnk"
                            >
                              <img src={linkedin_icon} alt="..." />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="ownseacrgresultpageright">
                  <p className="prPremiumofiletxtp">Sponsor Ad</p>
                  {/* <div className='ownseacrgresultpagerightbrdr'>
                                                <div className='ownseacrgresultpagerightbrdrtop'>
                                                    <div className='ownseacrgresultpagerreslppimg'>
                                                        <img src={imahggsercggdpremiy} alt='...' />
            
                                                    </div>
            
                                                    <div className='logostrdetyskksldovkkdpo'>
                                                        <div className='logostretdijjkkkljujuo'>
                                                            <img src={Neoologoasjji} alt='...' />
                                                        </div>
                                                        <div className='allttehdiioskjetyapasedoib'>
                                                            <p className='neosertsttfzrrtty'>Neoo Search FZE</p>
                                                            <p className='neosertsttfzrrttyadfd'>Digital Marketing</p>
                                                            <div className='dofleoodiistartdivflx'>
                                                                <p className='neosertsttfzrrttyadfdfff'>41 Followers</p>
            
                                                                <div className='statrrdibvggshhghuju'>
                                                                    <i class="fa-regular fa-star"></i>
                                                                    <i class="fa-regular fa-star"></i>
                                                                    <i class="fa-regular fa-star"></i>
                                                                    <i class="fa-regular fa-star"></i>
                                                                    <i class="fa-regular fa-star"></i>
                                                                </div>
                                                            </div>
            
                                                        </div>
                                                    </div>
                                                </div>
            
                                                <div className='ownseacrgresultpagerightbrdrtopbottom'>
                                                    <div className=''>
                                                        <p className='tttxtyaplattsyggjjkllkkk'>Levels 41, Emirates Towers, University Road</p>
                                                        <p className='tttxtyaplattsyggjjkllkkk'>Sharjah Emirate, United Arab Emirates</p>
            
                                                        <p className='phokennsjjsnyunnrbtk'>Phone: +971 553670071</p>
            
                                                        <Link className="serttxtghsytxoppjkjo" to="/">www.neoosearch.com</Link>
            
            
                                                        <p className='opperpoodshheyxgbpppn'>Products/Services</p>
                                                        <p className='diisjadfebnclkmdgragjksopjkl'>Digital marketing | Advertising | Content production
                                                            Digital business card | Graphic designing</p>
            
                                                        <div className='viewprofileimgaedivvhjjnflx'>
                                                            <div className='prijjdhjjjjkjaaimg'>
                                                                <img src={prijjdhjjjjkj} alt='...' />
                                                            </div>
                                                            <Link className='bvhhjjapsidhbfbbrkjk' to="/otherblmprofile">View profile</Link>
                                                        </div>
            
                                                        <div className=''>
                                                            <p className='sclloidjjdttxppphjo'>Social links</p>
                                                            <div className='socijjjsjkjijnndnkdobvbbhflx'>
                                                                <Link className='socijjjsjkjijnndnk'>
                                                                    <img src={facebook_icon} alt='...' />
                                                                </Link>
                                                                <Link className='socijjjsjkjijnndnk'>
                                                                    <img src={insta_icon} alt='...' />
                                                                </Link>
                                                                <Link className='socijjjsjkjijnndnk'>
                                                                    <img src={linkedin_icon} alt='...' />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                  <div className="boxosimaggedisahdowdiv">
                    <p className="tsttsggsiijxbhnsimag">300x250</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {ownpagesearchkeay && (
        <Ownpagesearchkeywordmodal closemodal={setownpagesearchkeay} />
      )}
    </>
  );
};

export default Blmemberlist;
