import React, { useState } from "react";
import editicon from "../../Images/editicon.png";
import Addservicemodal from "../../Modal/Addservicemodal";
import { useSelector } from "react-redux";

const Servicesblmprofile = ({ data, updateForm, setData }) => {
  const { UserProfileID } = useSelector((state) => state.app);
  
  const [aboyteditclickmodal, setAboyteditclickmodal] = useState(false);
  const [services, setServices] = useState(data?.businessProductCat || []);
  console.log("LINE6",data);

  const aboyteditclick = () => {
    setAboyteditclickmodal(!aboyteditclickmodal);
  };

  // Function to remove a service
  const removeService = (indexToRemove) => {
    const updatedServices = services.filter(
      (_, index) => index !== indexToRemove
    );
    setServices(updatedServices);
    updateForm("businessProductCat", updatedServices); // Update form with new services array
  };

  return (
    <>
      <div className="uploadbannerblmdiv">
        <div className="uploadbannerdivnewmain">
          <div className="consttactdetatlsarroedyrdiv">
            <p className="cinnattddttdtsttspo">Services</p>
            {
              UserProfileID?._id === data?._id &&
<div className="editeiconimg" onClick={aboyteditclick}>
              <img src={editicon} alt="Edit" />
            </div>
            }
            
          </div>
          <div className="adversytfservicexedib">
            {services.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                  backgroundColor: "silver",
                  padding: "5px 10px",
                  borderRadius: "15px",
                }}
                // className="servicdesdetatcxttxpp"
              >
                <p style={{ color: "#000", fontWeight: "500" }}>{item}</p>
                <i
                  className="fa-solid fa-xmark delete-icon"
                  onClick={() => removeService(index)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px",
                    color: "red",
                  }}
                ></i>
              </div>
            ))}
          </div>
        </div>
      </div>
      {aboyteditclickmodal && (
        <Addservicemodal
          services={services}
          setServices={setServices}
          updateForm={updateForm}
          closemodal={setAboyteditclickmodal}
          setData={setData}
        />
      )}
    </>
  );
};

export default Servicesblmprofile;
