import React, { useContext, useEffect, useState } from "react";
import bannerloginimage from "../../Images/bannerloginimage.png";
import { Colorcontext } from "../../Component/Colorcontext/ColorContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Workprogressbar from "./Workprogressbar";
import Selectplancomp from "./Selectplancomp";
import HttpClient from "../../utils/HttpClient";

const Searchloginform = () => {
  const navigate = useNavigate();
  const colordata = useContext(Colorcontext);
  const location = useLocation();
  const { choosePlanID } = location.state || {};
  const [selectedPlan, setSelectedPlan] = useState({ _id: "", name: "" });
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [formError, setFormError] = useState({
    usernameError: "",
    passwordError: "",
    checkboxError: "",
    captchaError: "",
  });

  const [validation, setValidation] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
    minLength: false,
  });

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    setValidation({
      lowercase: /[a-z]/.test(value),
      uppercase: /[A-Z]/.test(value),
      number: /\d/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      minLength: value.length >= 8,
    });
  };

  const getPlan = async () => {
    setLoading(true);
    const res = await HttpClient.requestData("subscription-plans", "GET", "");
    if (res && res.status) {
      setPlans(res?.data.reverse());
    } else {
      console.log("Error from getting subscription:", res);
    }
    setLoading(false);
  };

  const choosedPlan = () => {
    if (plans.length === 0 || !choosePlanID) return;
    const selected = plans.find((item) => item._id === choosePlanID);
    if (selected) {
      setSelectedPlan({ _id: selected._id, name: selected.plan });
    }
  };

  useEffect(() => {
    getPlan();
  }, []);

  useEffect(() => {
    choosedPlan();
  }, [plans, choosePlanID]);

  useEffect(() => {
    colordata.setcolor(true);
    return () => {
      colordata.setcolor(false);
    };
  }, [colordata]);
  const validateForm = () => {
    let isValid = true;

    // Validate username (ensure it's a valid email)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (username.trim() === "") {
      setFormError((prevState) => ({
        ...prevState,
        usernameError: "Username is required.",
      }));
      isValid = false;
    } else if (!emailRegex.test(username)) {
      setFormError((prevState) => ({
        ...prevState,
        usernameError: "Please enter a valid email address.",
      }));
      isValid = false;
    } else {
      setFormError((prevState) => ({ ...prevState, usernameError: "" }));
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,16}$/;
    if (password.trim() === "") {
      setFormError((prevState) => ({
        ...prevState,
        passwordError: "Password is required.",
      }));
      isValid = false;
    } else if (!passwordRegex.test(password)) {
      setFormError((prevState) => ({
        ...prevState,
        passwordError:
          "Password must be 8-16 characters long, include at least one uppercase letter, one lowercase letter, one digit, and one special character.",
      }));
      isValid = false;
    } else {
      setFormError((prevState) => ({ ...prevState, passwordError: "" }));
    }

    // Validate checkbox
    if (!isChecked) {
      setFormError((prevState) => ({
        ...prevState,
        checkboxError: "You must agree to the terms and privacy policy.",
      }));
      isValid = false;
    } else {
      setFormError((prevState) => ({ ...prevState, checkboxError: "" }));
    }

    // Validate captcha
    if (captchaAnswer.trim() !== "15") {
      setFormError((prevState) => ({
        ...prevState,
        captchaError: "Incorrect captcha answer.",
      }));
      isValid = false;
    } else {
      setFormError((prevState) => ({ ...prevState, captchaError: "" }));
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = {
        userName: username,
      };
      const res = await HttpClient.requestData("check-username", "POST", data);

      if (res.status) {
        console.log("The Response : ", res);
        navigate("/listbusiness", {
          state: { choosePlanID: selectedPlan._id },
        });
      } else {
        setFormError((prevState) => ({
          ...prevState,
          usernameError: "Email already exist, Please try with another email.",
        }));
      }
    } else {
      console.log("Form validation failed.");
    }
  };

  return (
    <section className="Searchloginformmain">
      <div className="cust_container">
        <div className="Workprogressbarmainb">
          <Workprogressbar page={1} />
        </div>

        <Selectplancomp
          plans={plans}
          setSelectedPlan={setSelectedPlan}
          selectedPlan={selectedPlan}
        />

        <div className="Searchloginformmainbg">
          <div className="Searchloginformmainleft">
            <div className="Searchloginformmainleftimg">
              <img src={bannerloginimage} alt="banner" />
            </div>
          </div>

          <div className="Searchloginformmainright">
            <div className="Searchloginformmainrightbg">
              <p className="creattusettxpp">Create Username and password</p>

              {/* Username Field with Validation */}
              <div className="usenametxttppmian">
                <p className="usenametxttpp">User name</p>
                <input
                  type="text"
                  placeholder="username@gmail.com"
                  className="usenametxttppinput"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              {formError.usernameError && (
                <p
                  className="error-message"
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {formError.usernameError}
                </p>
              )}

              <div className="usenametxttppmian">
                <p className="usenametxttpp">Password</p>
                <div className="usenametpasswrdifv">
                  <input
                    type={showPassword ? "text" : "password"} // Toggle password visibility
                    placeholder="Password"
                    className="usenametxttppinput"
                    value={password}
                    onChange={handlePasswordChange}
                  />

                  <div
                    className="showiconnimg"
                    onClick={() => setShowPassword(!showPassword)} // Toggle on click
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className={`fa-regular ${
                        showPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                    ></i>
                  </div>
                  <div className="passowrdrestritiondiv">
                    <div className="passowrdrestritiondivtop">
                      <p className="minimumpasswodttrxpp">
                        The minimum password length is 8 characters and must
                        contain at least 1 lowercase letter, 1 capital letter, 1
                        number, and 1 special character.
                      </p>
                    </div>
                    <div className="passowrdrestritiondivbottom">
                      <div className="radionattstxtdibflx">
                        <div
                          className={`radiobacustomdesign ${
                            validation.minLength ? "tick-green" : ""
                          }`}
                        >
                          {validation.minLength && (
                            <i
                              className="fa fa-check"
                              style={{ color: "green" }}
                            ></i>
                          )}
                        </div>
                        <label className="radiobtntxtxtplalbel">
                          Minimum 8 characters
                        </label>
                      </div>

                      <div className="radionattstxtdibflx">
                        <div
                          className={`radiobacustomdesign ${
                            validation.lowercase ? "tick-green" : ""
                          }`}
                        >
                          {validation.lowercase && (
                            <i
                              className="fa fa-check"
                              style={{ color: "green" }}
                            ></i>
                          )}
                        </div>
                        <label className="radiobtntxtxtplalbel">
                          Lowercase letter
                        </label>
                      </div>

                      <div className="radionattstxtdibflx">
                        <div
                          className={`radiobacustomdesign ${
                            validation.uppercase ? "tick-green" : ""
                          }`}
                        >
                          {validation.uppercase && (
                            <i
                              className="fa fa-check"
                              style={{ color: "green" }}
                            ></i>
                          )}
                        </div>
                        <label className="radiobtntxtxtplalbel">
                          Capital letter
                        </label>
                      </div>

                      <div className="radionattstxtdibflx">
                        <div
                          className={`radiobacustomdesign ${
                            validation.number ? "tick-green" : ""
                          }`}
                        >
                          {validation.number && (
                            <i
                              className="fa fa-check"
                              style={{ color: "green" }}
                            ></i>
                          )}
                        </div>
                        <label className="radiobtntxtxtplalbel">Number</label>
                      </div>

                      <div className="radionattstxtdibflx">
                        <div
                          className={`radiobacustomdesign ${
                            validation.specialChar ? "tick-green" : ""
                          }`}
                        >
                          {validation.specialChar && (
                            <i
                              className="fa fa-check"
                              style={{ color: "green" }}
                            ></i>
                          )}
                        </div>
                        <label className="radiobtntxtxtplalbel">
                          Special character
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {formError.passwordError && (
                <p
                  className="error-message"
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {formError.passwordError}
                </p>
              )}

              <div className="agereecheck">
                <input
                  type="checkbox"
                  className="schekbdttstxttpp"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
                <p className="agreerxtppptt">
                  I agree with the <span>Terms of services</span> and{" "}
                  <span>Privacy Policy</span>
                </p>
              </div>
              {formError.checkboxError && (
                <p
                  className="error-message"
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {formError.checkboxError}
                </p>
              )}

              <div className="addedasldibv">
                <span className="addersrquesttsxtpp">7 + 8 = ?</span>
                <input
                  type="text"
                  className="addesdwqtssxtoipnt"
                  value={captchaAnswer}
                  onChange={(e) => setCaptchaAnswer(e.target.value)}
                />
              </div>
              {formError.captchaError && (
                <p
                  className="error-message"
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {formError.captchaError}
                </p>
              )}

              <div className="btnnexttdivbtnmain">
                <button className="btnnexttdivbtn" onClick={handleSubmit}>
                  Create account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Searchloginform;
