import React, { useState } from "react";
import vectorarrow from "../../Images/vectorarrow.png";
const Selectplancomp = ({ plans, setSelectedPlan, selectedPlan }) => {
  const [drowdowsncustom, setDrowdowsncustom] = useState(false);
  const [dropDownOpen, setDropdownOpen] = useState(false);
  const drowdowncustclick = () => {
    setDrowdowsncustom(!drowdowsncustom);
  };
  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  return (
    <>
      <div className="listbusinessdivleftbg">
        <div className="selectpalndivnewcontent">
          <div className="contatcindofdiinpurtxtpdivmaoibjj">
            <p className="contatcindofdiinpurtxtp">
              {" "}
              Selected plan <span> *</span>
            </p>
            <p className="basicjleisttxtp"> Basic listing</p>
          </div>
          <div className="dropwdownseletcmewdivfrmbusinee">
            <p className="vectoraarowdivomgulmaili" style={{ color: "#000" }}>
              {selectedPlan?.name ? selectedPlan?.name : "Basic"}
            </p>
            <div className="vectoraarowdivomg" onClick={drowdowncustclick}>
              <img
                style={{
                  cursor: "pointer",
                }}
                src={vectorarrow}
                alt="..."
              />
            </div>
            {drowdowsncustom && (
              <div className="vectoraarowdivomgulmai">
                <ul className="vectoraarowdivomgulmaiul">
                  {plans.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className="vectoraarowdivomgulmaili"
                        onClick={() => {
                          setSelectedPlan({
                            _id: item?._id,
                            name: item?.plan,
                          });
                          setDrowdowsncustom(false);
                        }}
                      >
                        {item?.plan}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Selectplancomp;
