import React from "react";
import editicon from "../../Images/editicon.png";
import { useState } from "react";
import Editaboutbmlmodal from "../../Modal/Editaboutbmlmodal";
import { useSelector } from "react-redux";
const Aboutusblmprofile = ({ data, updateForm }) => {
  const { UserProfileID } = useSelector((state) => state.app);
  const [aboyteditclickmodal, setAboyteditclickmodal] = useState(false);
  const aboyteditclick = () => {
    setAboyteditclickmodal(!aboyteditclickmodal);
  };
  console.log("LINE10",data);
  
  return (
    <>
      <div className="uploadbannerblmdiv">
        <div className="uploadbannerdivnewmain">
          <div className="consttactdetatlsarroedyrdiv">
            <p className="cinnattddttdtsttspo">About Us</p>
            {
              UserProfileID?._id === data?._id &&
              <div className="editeiconimg" onClick={aboyteditclick}>
              <img src={editicon} alt="..." />
            </div>
            }
            
          </div>
          <div className="abttsrtxtdivvnappadin">
            <p className="abttsbttetxtttpp">{data?.aboutBusiness || ""}</p>
            {/* <p className='abttsbttetxtttpp'>
                            We have provided a wealth of information to help you market your business by letting you search for and access your target customers. The platform allows you to create unlimited opportunities that are visible to thousands of other companies in the world. You can also search for and contact other entrepreneurs in a safe and reliable environment. Besides, you can also use search tools to generate more leads with Neoo Search.
                        </p> */}
          </div>
        </div>
      </div>
      {aboyteditclickmodal && (
        <Editaboutbmlmodal
          fdata={data}
          updateForm={updateForm}
          closemodal={setAboyteditclickmodal}
        />
      )}
    </>
  );
};

export default Aboutusblmprofile;
