import React, { useState } from "react";

const Editaboutbmlmodal = ({ closemodal, updateForm,fdata }) => {
  const [data, setData] = useState("");
  console.log("LINE05",fdata);
  
  const handleChange = (event) => {
    setData(event.target.value);
  };

  const handleSave = () => {
    updateForm("aboutBusiness", data); // Update form with the "about" field data
    closemodal(); // Close modal after saving
  };

  return (
    <div className="edittamodalboixshadow">
      <div className="edittamodalboixsbg">
        {/* Close button */}
        <div className="crossdivicon" onClick={closemodal}>
          <i className="fa-solid fa-xmark"></i>
        </div>

        {/* Header */}
        <div className="edittappduudivnhhb">
          <h4 className="upldbannertrtxtpp">Edit About</h4>
        </div>

        {/* Text area for input */}
        <div className="abattstdovvstttschgg">
          <textarea
            onChange={handleChange}
            value={data?data:fdata.aboutBusiness}
            rows={4}
            cols={4}
            className="abattstdovvstttschggttstxt"
            placeholder="Write something about yourself..."
          />
          <p className="ttsrrhhshwoolumiuitstxtt">100-250 words</p>

          {/* Save button */}
          <div className="svavtatvvbtnnmpaggdijbtn">
            {data && (
              <button onClick={handleSave} className="svavtatvvbtnnm">
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editaboutbmlmodal;
