import React, { useState } from "react";

const Addservicemodal = ({ closemodal, services, setServices, updateForm }) => {
  const [addService, setAddService] = useState("");

  const handleChange = (e) => {
    setAddService(e.target.value);
  };

  const submit = () => {
    const updatedServices = [...services, addService]; 
    setServices(updatedServices); 
    updateForm("businessProductCat", updatedServices); 
    setAddService(""); 
    closemodal(false);
  };

  return (
    <div className="edittamodalboixshadow">
      <div className="edittamodalboixsbg">
        <div className="crossdivicon" onClick={() => closemodal(false)}>
          <i className="fa-solid fa-xmark"></i>
        </div>
        <div className="edittappduudivnhhb">
          <h4 className="upldbannertrtxtpp">Add Service</h4>
        </div>
        <div className="abattstdovvstttschgg">
          <textarea
            value={addService}
            onChange={handleChange}
            rows={4}
            cols={4}
            className="addservicetextdivatreediv"
            placeholder="Enter service details..."
          />
          <p className="ttsrrhhshwoolumiuitstxtt">100-250 words</p>
          {addService && (
            <div className="svavtatvvbtnnmpaggdijbtn">
              <button onClick={submit} className="svavtatvvbtnnm">
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Addservicemodal;
