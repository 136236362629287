import React, { useState } from "react";

const Addsearchkeywordmodal = ({
  closemodal,
  services,
  setServices,
  updateForm,
}) => {
  const [newKeyword, setNewKeyword] = useState("");

  const handleChange = (e) => {
    setNewKeyword(e.target.value);
  };

  const submit = () => {
    const updatedServices = [...services, newKeyword];
    setServices(updatedServices);
    updateForm("businessProductkeywords", updatedServices);
    setNewKeyword("");
    closemodal(false);
  };

  return (
    <div className="edittamodalboixshadow">
      <div className="edittamodalboixsbg">
        <div className="crossdivicon" onClick={() => closemodal(false)}>
          <i className="fa-solid fa-xmark"></i>
        </div>
        <div className="edittappduudivnhhb">
          <h4 className="upldbannertrtxtpp">Edit Keyword</h4>
        </div>
        <div className="abattstdovvstttschgg">
          <textarea
            value={newKeyword}
            onChange={handleChange}
            rows={4}
            cols={4}
            className="addservicetextdivatreediv"
            placeholder="Enter keyword details..."
          />
          <p className="ttsrrhhshwoolumiuitstxtt">100-250 words</p>
          {newKeyword && (
            <div className="svavtatvvbtnnmpaggdijbtn">
              <button onClick={submit} className="svavtatvvbtnnm">
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Addsearchkeywordmodal;
