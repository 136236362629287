import React, { useState } from "react";
import { Link, useHistory, useNavigate } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";

const Workprogressbar = ({ page, data: stepsData = [] }) => {
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();
  const submitData = async () => {
    Navigate("/paymentpage", { state: { stepsData } });
    
  };

  const steps = [
    { id: 1, name: "Create account" },
    { id: 2, name: "Contact information" },
    { id: 3, name: "Update page" },
    { id: 4, name: "Payment" },
  ];

  return (
    <section className="workprogressdivsection">
      <div className="workprogressdivdivmnaindlx">
        <div className="workprogressdivalldatadiv">
          <div
            className="workprogressdivmain"
            style={{ backgroundColor: "#ccc" }}
          ></div>

          {steps.map((item) => (
            <div className="workprogressdivcriclemain" key={item.id}>
              <div
                className="workprogressdivcricle"
                style={
                  page === item.id
                    ? { backgroundColor: "#02CC52" }
                    : { backgroundColor: "#ccc" }
                }
              >
                <p
                  className="workprogressdivcricletxtp"
                  style={{ color: "#000" }}
                >
                  {item.id}
                </p>
                <p className="workprogressdivcricletxtpdes">{item.name}</p>
              </div>
            </div>
          ))}
        </div>

        {page === 3 || page === 4 ? (
          <div className="viewsyatpaynowewdivflx">
            <button className="viewStatusdivbtnb">View Status</button>
            {page === 3 && (
              <button
                className="paynowbtnb"
                onClick={submitData}
                disabled={loading} // Disable button while loading
              >
                {loading ? "Processing..." : "Pay now"}
              </button>
            )}
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default Workprogressbar;
