import React, { useState } from "react";
import editicon from "../../Images/editicon.png";
import mobile_icon from "../../Images/mobile_icon.png";
import phone_icon from "../../Images/phone_icon.png";
import whatsapp_icon from "../../Images/whatsapp_icon.png";
import mail_icon from "../../Images/mail_icon.png";
import website_icon from "../../Images/website_icon.png";
import location from "../../Images/location.png";
import arrowdirections from "../../Images/arrowdirections.png";
import Companyinfoeditmodal from "../../Modal/Companyinfoeditmodal";
import { useSelector } from "react-redux";
const ContactDetailItem = ({ icon, text }) => {
  console.log("text123:", text); // Log text to check values
  return (
    <div className="infodetatddivfllsxdlx">
      <div className="indfodetatildiv">
        <img src={icon} alt="icon" />
      </div>
      <p className="ingfddtertrrxrpp">{text || "N/A"}</p>
    </div>
  );
};

const Contactdetaiilsprofile = ({ data = {}, setData, updateForm }) => {
  const { UserProfileID } = useSelector((state) => state.app);
  const [contactEditModal, setContactEditModal] = useState(false);
  const handleContactModalClick = () => setContactEditModal(!contactEditModal);
  console.log("LINE26",data);
  
  return (
    <>
      <div className="contactdetsdiv">
        <div className="contactdetsdivdivbmain">
          <div className="consttactdetatlsarroedyrdiv">
            <p className="cinnattddttdtsttspo">Contact Details</p>
            {
              UserProfileID?._id === data?._id &&
              <div className="editeiconimg" onClick={handleContactModalClick}>
              <img src={editicon} alt="edit icon" />
            </div>
            }
            
          </div>
          <div className="indfodetatildivmainui">
            <ContactDetailItem
              icon={mobile_icon}
              text={data.mobileNo || "N/A"}
            />
            <ContactDetailItem
              icon={phone_icon}
              text={data.telephoneNo || "N/A"}
            />
            <ContactDetailItem
              icon={whatsapp_icon}
              text={data.mobileNo || "N/A"}
            />
            <ContactDetailItem icon={mail_icon} text={data.emailId || "N/A"} />
            <ContactDetailItem
              icon={website_icon}
              text={data.webAddress || "N/A"}
            />
          </div>
        </div>

        <div className="contactdetsdivdivbmaindivbtm">
          <ContactDetailItem icon={location} text={data.address} />
          {/* <div className="direxrrcttstbtnmain">
            <button className="direxrrcttstbtn">
              Get Directions
              <img src={arrowdirections} alt="arrow icon" />
            </button>
          </div> */}
        </div>
      </div>

      {contactEditModal && (
        <Companyinfoeditmodal
          data={data}
          closemodal={handleContactModalClick}
          updateForm={updateForm}
          setData={setData}
        />
      )}
    </>
  );
};

export default Contactdetaiilsprofile;
