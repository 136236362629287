import React, { useState } from "react";
import editicon from "../../Images/editicon.png";
import galleryimg from "../../Images/galleryimg.png";
import productdesign from "../../Images/productdesign.png";
import Advertising from "../../Images/Advertising.png";
import DigitalMarketing from "../../Images/DigitalMarketing.png";
import Photography from "../../Images/Photography.png";
import Galleryeditmodal from "../../Modal/Galleryeditmodal";
import Editgalleryproductmodal from "../../Modal/Editgalleryproductmodal";
import Otherproductmodal from "../../Modal/Otherproductmodal";
import { useSelector } from "react-redux";
const Galleryblmprofile = ({ data = { gallery: [] }, updateForm, setData }) => {
  const { UserProfileID } = useSelector((state) => state.app);
  console.log("Dataaa12 : ", data);
  const [galleymodala, setGalleymodala] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [editshoeopen, setEditshoeopen] = useState(false);
  const [editgallerproductmodal, setEditgallerproductmodal] = useState(false);
  const [beautyproductmodal, setBeautyproductmodal] = useState(false);
  const productmodalotherclcik = () => {
    setBeautyproductmodal(!beautyproductmodal);
  };
  const gallerclickmodal = () => {
    setGalleymodala(!galleymodala);
  };
  const closemodal = () => {
    setEditgallerproductmodal(false);
  };
  const edishowclickmm = () => {
    setEditshoeopen(!editshoeopen);
  };

  const editgalleryssclick = (item) => {
    setSelectedImage(item);
    setEditgallerproductmodal(true);
  };
  return (
    <>
      <div className="uploadbannerblmdiv">
        <div className="uploadbannerdivnewmain">
          <div className="consttactdetatlsarroedyrdiv">
            <p className="cinnattddttdtsttspo">Gallery</p>
            {
              UserProfileID?._id === data?._id &&
<div className="editeiconimg" onClick={gallerclickmodal}>
              <img src={editicon} alt="..." />
            </div>
            }
            
          </div>
          <div className="uploadimagedivflx">
            {Array.isArray(data?.gallery) &&
              data.gallery.map((item, index) => {
                return (
                  <div className="uploadimagebg" key={index}>
                    <div className="uploadimagebgmain">
                      <div className="editeiconimghideenalda">
                        {/* <i
                          onClick={() => editgalleryssclick(item)}
                          className="fa-solid fa-pen-to-square mr-4"
                        ></i> */}
                        {/* <i className="fa-solid fa-xmark delete-icon"></i> */}
                      </div>
                      <div className="cameralogodivimgabslt">
                        <div className="cameralogodivimg">
                          <img
                            style={{
                              cursor: "pointer",
                            }}
                            src={item?.image}
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>

                    <p
                      className="imagetitrtlstrtrxpp"
                      onClick={productmodalotherclcik}
                    >
                      {item?.title}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      {galleymodala && (
        <Galleryeditmodal
          data={data}
          updateForm={updateForm}
          closemodal={setGalleymodala}
          setData={setData}
        />
      )}
      {editgallerproductmodal && (
        <Editgalleryproductmodal
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          data={data}
          updateForm={updateForm}
          closemodal={closemodal}
          setData={setData}
        />
      )}
      {beautyproductmodal && (
        <Otherproductmodal closemodal={setBeautyproductmodal} />
      )}
    </>
  );
};

export default Galleryblmprofile;
