import React, { useState } from "react";
import "./Searchhome.css";
import neofrontlogo from "../../Images/neofrontlogo.png";
import searchicon from "../../Images/searchicon.png";
import { useNavigate } from "react-router-dom";
import close from "../../Images/close.png";
import HttpClient from "../../utils/HttpClient";
const Searchhome = () => {
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const searchresultclick = async () => {
    if (text) {
      let data = {
        searchName: text,
      };
      const response = await HttpClient.requestData(
        "search-business",
        "POST",
        data
      );
      if (response?.status) {
        navigate("/blmamberlist", {
          state: {
            searchText: text,
            data: response?.data,
          },
        });
      } else {
        console.log("Error while fetching the data ");
      }
    }
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <>
      <section className="searchhomemain">
        <div className="cust_container">
          <div className="midownseacrhmaindivnew">
            <div className="logedserchfrminptnew">
              <input
                type="text"
                value={text}
                onChange={handleChange}
                placeholder="Neoo search dubai"
                className="logedserchfrminptinptnew"
              />
              {text && (
                <div
                  className="searchiconimgnew"
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10%",
                    cursor: "pointer",
                  }}
                  onClick={() => setText("")}
                >
                  <img
                    style={{
                      height: "13px",
                      width: "13px",
                    }}
                    src={close}
                    alt="closing button"
                  />
                </div>
              )}
              <div className="searchiconimgnew" onClick={searchresultclick}>
                <img src={searchicon} alt="..." />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Searchhome;
