import React, { useState } from "react";
import vectorarrow from "../../src/Images/vectorarrow.png";
const Ownpagesearchkeywordmodal = ({ closemodal }) => {
  const [categoryModel, setCategoryModel] = useState(false);
  const [stateModel, setStateModel] = useState(false);
  const [countryModel, setCountryModel] = useState(false);
  const [arrowmodalclcik, setarrowmodalclcik] = useState(false);
  const [filterKey, setFilterKey] = useState({
    product: "",
    productCat: "",
    location: "",
    state: "",
    country: "65f95f070d6753c3bdacccd9",
  });

  const handleInsertData = (key, value) => {
    setFilterKey((prev) => ({ ...prev, [key]: value }));
  };
  const handlearrpwclick = () => {
    setarrowmodalclcik(!arrowmodalclcik);
  };
  return (
    <div className="uploabannermodalbgshadow ">
      <div className="owenoagedjeywordbgmain">
        <div className="crossdivmainmnm" onClick={() => closemodal()}>
          <i className="fa-solid fa-xmark"></i>
        </div>

        <div className="keywirdrserchttxtdibgflxmain">
          <div className="keywirdrserchttxtdibgflx">
            <div className="keywirdrserchdibvnain">
              <p className="keywirdrserchdibvnainttxpp">KeyWord</p>
            </div>
            <div className="keywirdrserchdibinpiuytxtdiv">
              <input
                type="text"
                value={filterKey?.product}
                onChange={(e) => handleInsertData("product", e.target.value)}
                className="keywprodsecrtinputmain"
                placeholder="Product or Service name"
              />
            </div>
          </div>

          <div className="keywirdrserchttxtdibgflx">
            <div className="keywirdrserchdibvnain">
              <p className="keywirdrserchdibvnainttxpp">Category</p>
            </div>
            <div className="keywirdrserchdibinpiuytxtdiv">
              <div className="keywirdrserchdibderselect">
                <p className="selrcttcatreffgtxtpp">Select category</p>
                <div
                  className="vectorimgamindiv"
                  onClick={() => setCategoryModel(!categoryModel)}
                >
                  <img src={vectorarrow} alt="..." />
                </div>
                {categoryModel && (
                  <div className="vectoraarowdivomgulmai">
                    <ul className="vectoraarowdivomgulmaiul">
                      <li className="vectoraarowdivomgulmaili">
                        Select category
                      </li>
                      <li className="vectoraarowdivomgulmaili">
                        Select category
                      </li>
                      <li className="vectoraarowdivomgulmaili">
                        Select category
                      </li>
                      <li className="vectoraarowdivomgulmaili">
                        Select category
                      </li>
                      <li className="vectoraarowdivomgulmaili">
                        Select category
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="keywirdrserchttxtdibgflx">
            <div className="keywirdrserchdibvnain">
              <p className="keywirdrserchdibvnainttxpp">Page</p>
            </div>
            <div className="keywirdrserchdibinpiuytxtdiv">
              <div className="">
                <div className="chekowcutbnemttxndivflx">
                  <input
                    type="checkbox"
                    name=""
                    defaultValue=""
                    className="chekowcutomedesign"
                  />
                  <label htmlFor="" className="labbnemttxptt">
                    {" "}
                    All
                  </label>
                </div>

                <div className="chekowcutbnemttxndivflx">
                  <input
                    type="checkbox"
                    name=""
                    defaultValue=""
                    className="chekowcutomedesign"
                  />
                  <label htmlFor="" className="labbnemttxptt">
                    Premium Members
                  </label>
                </div>

                <div className="chekowcutbnemttxndivflx">
                  <input
                    type="checkbox"
                    name=""
                    defaultValue=""
                    className="chekowcutomedesign"
                  />
                  <label htmlFor="" className="labbnemttxptt">
                    Verified list
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="keywirdrserchttxtdibgflx">
            <div className="keywirdrserchdibvnain">
              <p className="keywirdrserchdibvnainttxpp">Location</p>
            </div>
            <div className="keywirdrserchdibinpiuytxtdiv">
              <input
                type="text"
                value={filterKey?.location}
                onChange={(e) =>
                  setFilterKey({ ...filterKey, location: e.target.value })
                }
                className="keywprodsecrtinputmain"
                placeholder="Product or Service name"
              />
            </div>
          </div>

          <div className="keywirdrserchttxtdibgflx">
            <div className="keywirdrserchdibvnain">
              <p className="keywirdrserchdibvnainttxpp">State</p>
            </div>
            <div className="keywirdrserchdibinpiuytxtdiv">
              <div className="keywirdrserchdibderselect">
                <p className="selrcttcatreffgtxtpp">Select category</p>
                <div
                  className="vectorimgamindiv"
                  onClick={() => setStateModel(!stateModel)}
                >
                  <img src={vectorarrow} alt="..." />
                </div>
                {stateModel && (
                  <div className="vectoraarowdivomgulmai">
                    <ul className="vectoraarowdivomgulmaiul">
                      <li className="vectoraarowdivomgulmaili">
                        Select category
                      </li>
                      <li className="vectoraarowdivomgulmaili">
                        Select category
                      </li>
                      <li className="vectoraarowdivomgulmaili">
                        Select category
                      </li>
                      <li className="vectoraarowdivomgulmaili">
                        Select category
                      </li>
                      <li className="vectoraarowdivomgulmaili">
                        Select category
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="keywirdrserchttxtdibgflx">
            <div className="keywirdrserchdibvnain">
              <p className="keywirdrserchdibvnainttxpp">Country</p>
            </div>
            <div className="keywirdrserchdibinpiuytxtdiv">
              <div className="keywirdrserchdibderselect">
                <p className="selrcttcatreffgtxtpp">United Arab Emirates </p>
                <div
                  className="vectorimgamindiv"
                  onClick={() => setCountryModel(!countryModel)}
                >
                  <img src={vectorarrow} alt="..." />
                </div>
                {countryModel && (
                  <div className="vectoraarowdivomgulmai">
                    <ul className="vectoraarowdivomgulmaiul">
                      <li className="vectoraarowdivomgulmaili">
                        United Arab Emirates
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="cancelbscearchbtntndiv">
            <button className="cancelbtn">Cancel</button>
            <button className="scearchbtn">Search</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ownpagesearchkeywordmodal;
