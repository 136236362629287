import React, { useContext, useEffect, useState } from "react";
import EditProfileblm from "./EditProfileblm";
import { Colorcontext } from "../../Component/Colorcontext/ColorContext";
import Blmpostadd from "./Blmpostadd";
import Viewersengagement from "./Viewersengagement";
import Upgradeprofile from "./Upgradeprofile";
import Contactdetaiilsprofile from "./Contactdetaiilsprofile";
import Uploadbannerblm from "./Uploadbannerblm";
import Aboutusblmprofile from "./Aboutusblmprofile";
import Servicesblmprofile from "./Servicesblmprofile";
import Socilallinkprofile from "./Socilallinkprofile";
import Galleryblmprofile from "./Galleryblmprofile";
import Workprogressbar from "./Workprogressbar";
import Searchkeywordprofile from "./Searchkeywordprofile";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserLocation } from "../../Redux/Slice/PostSlice";
import HttpClient from "../../utils/HttpClient";

const UserProfile = () => {
  // const colordata = useContext(Colorcontext);
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { SearchBar, ProfileSubmenuDropDownToggle, UserProfileID } =
    useSelector((state) => state.app);

  const { choosePlanID } = location.state || {};
  const [UserMemberData, setUserMemberData] = useState({});

  console.log("LINE31", params.name);



  // const [formData, setFormData] = useState({
  //   subscriptionId:choosePlanID,
  //   personName: UserProfileID?.personName,
  //   designationID: UserProfileID?.designationID,
  //   companyTypeID: UserProfileID?.companyTypeID,
  //   mobileNo: UserProfileID?.mobileNo,
  //   telephoneNo: UserProfileID?.telephoneNo,
  //   emailId: UserProfileID?.emailId,
  //   address: UserProfileID?.address,
  //   emirate: UserProfileID?.emirate,
  //   companyName: UserProfileID?.companyName,
  //   webAddress: UserProfileID?.webAddress,
  //   location: UserProfileID?.location,
  //   country: "65f95f070d6753c3bdacccd9"
  // });

  // const [formData, setFormData] = useState(UserProfileID);
  const [formData, setFormData] = useState();

  console.log("LINE50", choosePlanID);





  const [data, setData] = useState();

  const getUserProfile = async () => {
    // setisLoading(true);
    const res = await HttpClient.requestData("get-profile", "GET", {});
    // console.log(res?.data, "userprofile");
    if (res && res?.status) {
      // setisLoading(false);
      const updatedProfileData = {
        ...res?.data,
        aboutBusiness: res?.data.companyDescription,
        subscriptionId: choosePlanID,
      };
      setData(updatedProfileData);
      dispatch(UserLocation(res?.data));
      // dispatch(UserProfileIDget(res?.data?._id));
    } else {
      // setisLoading(false);
    }
  };

  const getUserProfileByName = async () => {
    // setisLoading(true);
    const sendData = {
      userName: params?.name,
    };
    const res = await HttpClient.requestData(
      "get-profile-via-share-link",
      "POST",
      sendData
    );
    console.log("LINE91", res.data[0]);

    if (res && res?.status) {
      // setisLoading(false);
      const updatedProfileData = {
        ...res?.data[0],
        aboutBusiness: res?.data[0].companyDescription,
        // subscriptionId:choosePlanID,
      };
      setData(updatedProfileData);
      // dispatch(UserLocation(res?.data));
      // dispatch(UserProfileIDget(res?.data?._id));
    } else {
      // setisLoading(false);
    }
  };

  // const [isMyProfile, setIsMyProfile] = useState(false)



  useEffect(() => {
    // savedContactList();
    // getUserProfile();
    getUserProfileByName();
    // data?._id === UserProfileID?._id ? setIsMyProfile(true) : setIsMyProfile(false)
  }, [params]);



  // useEffect(() => {
  //   colordata.setcolor(true);
  //   return () => {
  //     colordata.setcolor(false);
  //   };
  // }, []);

  useEffect(() => {
    console.log("RESP", data);
    UserProfileID?.memberStatus && updateData(data)
  }, [data])

  console.log("LINE29", UserProfileID?.memberStatus);

  const updateData = async (data) => {

    const updatedBusinessData = {
      ...data,
    };

    // Remove properties
    delete updatedBusinessData._id;
    delete updatedBusinessData.password;
    delete updatedBusinessData.token;
    delete updatedBusinessData.SLNo;
    delete updatedBusinessData.TypeOfCompany;
    delete updatedBusinessData.avgRating;
    delete updatedBusinessData.designation;
    delete updatedBusinessData.doc;
    delete updatedBusinessData.likeCount;
    delete updatedBusinessData.likes;
    delete updatedBusinessData.loginStatus;
    delete updatedBusinessData.memberStatus;
    delete updatedBusinessData.partnerStatus;
    delete updatedBusinessData.passwordView;
    delete updatedBusinessData.productData;
    delete updatedBusinessData.rating;
    delete updatedBusinessData.referralStatus;
    delete updatedBusinessData.service;
    delete updatedBusinessData.serviceID;
    delete updatedBusinessData.serviceName;
    delete updatedBusinessData.upgradeStatus;


    try {
      const response = await HttpClient.requestData(
        `update-business/${UserProfileID?._id}`,
        "PUT",
        updatedBusinessData
      );
      if (response.status) {
        // Navigate to payment page
        // Navigate("/searchhome");
        // Navigate("/");
        console.log("RESP", response, data);

      } else {
        console.error("Error from listing business");
        console.log("RESP", response, data);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      console.log("RESP", error, data);
    } finally {
    }
  }

  const updateForm = (key, value) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };


  return (
    <>
      <section className="ownblmprofiledivmaoin "
        style={{
          paddingTop: "15px"
        }}
      >
        <div className="cust_container">
          {
            !UserProfileID?.businessStatus &&
            <div className="Workprogressbarmainb">
              <Workprogressbar data={data} page={3} />
            </div>
          }


          <div className=" reposivecideforspecial">
            <div className="ownblmprofiledivleft">
              <div className="repsonsivedivcls">
                {/* For Tablet responsive code start  */}
                <div className=" oenblmsnfidivmainleft">
                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain">
                    <EditProfileblm updateForm={updateForm} data={data} />
                  </div>

                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain">
                    <Contactdetaiilsprofile
                      setData={setData}
                      data={data}
                      updateForm={updateForm}
                    />
                  </div>
                </div>

                <div className="oenblmsnfidivmainright">
                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain ">
                    <Blmpostadd />
                  </div>
                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain">
                    <Viewersengagement />
                  </div>
                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain ">
                    <Upgradeprofile />
                  </div>
                  <div className="oenblmsnfdrpbapbbtm responsivdedivmain">
                    <Uploadbannerblm data={data} updateForm={updateForm} />
                  </div>
                </div>
                {/* For Tablet responsive code  end */}
              </div>

              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <EditProfileblm updateForm={updateForm} data={data} />
              </div>

              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <Blmpostadd />
              </div>

              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <Viewersengagement />
              </div>

              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <Upgradeprofile />
              </div>
              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <Contactdetaiilsprofile
                  setData={setData}
                  data={data}
                  updateForm={updateForm}
                />
              </div>
            </div>
            <div className="ownblmprofiledivright">
              <div className="oenblmsnfdrpbapbbtm deslrtopwdijjsmain">
                <Uploadbannerblm data={data} updateForm={updateForm} />
              </div>
              <div className="oenblmsnfdrpbapbbtm">
                <Aboutusblmprofile data={data} updateForm={updateForm} />
              </div>

              <div className="oenblmsnfdrpbapbbtm">
                <Servicesblmprofile data={data} updateForm={updateForm} setData={setData} />
              </div>

              <div className="oenblmsnfdrpbapbbtm">
                <Searchkeywordprofile data={data} updateForm={updateForm} />
              </div>

              <div className="oenblmsnfdrpbapbbtm">
                <Galleryblmprofile
                  setData={setData}
                  data={data}
                  updateForm={updateForm}
                />
              </div>
              <div className="oenblmsnfdrpbapbbtm">
                <Socilallinkprofile updateForm={updateForm} data={data} />
              </div>
            </div>
          </div>

          {/* Mobile Screen Design Start */}

          <div className="mobilereposiveprofile">
            <div className="bannerprofileblmreposnive">
              <Uploadbannerblm data={data} updateForm={updateForm} />
            </div>
            <div className="mobilereposvispsicaldivflx">
              <div className="eidteprofileblmreposnive">
                <EditProfileblm updateForm={updateForm} data={data} />
              </div>

              <div className="viewsprofileprofileblmreposnive">
                <div className="">
                  <Viewersengagement />
                </div>
                <div className="bklmlposprofileprofileblmreposnive">
                  <Blmpostadd />
                </div>

                <div className="">
                  <Upgradeprofile />
                </div>
              </div>
            </div>
            <div className="contaallprofileblmreposnive">
              <Contactdetaiilsprofile
                setData={setData}
                data={data}
                updateForm={updateForm}
              />
            </div>
            <div className="contaallprofileblmreposnive">
              <Aboutusblmprofile data={data} updateForm={updateForm} />
            </div>
            <div className="contaallprofileblmreposnive">
              <Servicesblmprofile data={data} updateForm={updateForm} />
            </div>
            <div className="contaallprofileblmreposnive">
              <Galleryblmprofile
                setData={setData}
                data={data}
                updateForm={updateForm}
              />
            </div>
            <div className="contaallprofileblmreposnive">
              <Socilallinkprofile updateForm={updateForm} data={data} />
            </div>
          </div>

          {/* Mobile Screen Design End */}
        </div>
      </section>
    </>
  );
};

export default UserProfile;
