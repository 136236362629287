import React, { useState } from "react";
import editicon from "../../Images/editicon.png";
import facebook_icon from "../../Images/facebook_icon.png";
import insta_icon from "../../Images/insta_icon.png";
import linkedin_icon from "../../Images/linkedin_icon.png";
import Editsocilamediamodal from "../../Modal/Editsocilamediamodal";
import { useSelector } from "react-redux";
const Socilallinkprofile = ({ updateForm, data }) => {
  const { UserProfileID } = useSelector((state) => state.app);
  const [editcsolisdmoidala, setEditcsolisdmoidala] = useState(false);
  const editsoicilksaincclick = () => {
    setEditcsolisdmoidala(!editcsolisdmoidala);
  };
  return (
    <>
      <div className="uploadbannerblmdiv">
        <div className="uploadbannerdivnewmain">
          <div className="consttactdetatlsarroedyrdiv">
            <p className="cinnattddttdtsttspo">Social links</p>
            {
              UserProfileID?._id === data?._id &&
              <div className="editeiconimg" onClick={editsoicilksaincclick}>
                <img src={editicon} alt="..." />
              </div>
            }

          </div>
          <div className="fallsebblldivmaindivflxl">
            <div className="fallsebblldivmain">
              <div className="icopndibimahhmainimjh">
                <img src={facebook_icon} alt="..." />
              </div>
              <p className="faccevttxttpphhtxtpp">Facebook </p>
            </div>
            <div className="fallsebblldivmain">
              <div className="icopndibimahhmainimjh">
                <img src={insta_icon} alt="..." />
              </div>
              <p className="faccevttxttpphhtxtpp">Instagram</p>
            </div>
            <div className="fallsebblldivmain">
              <div className="icopndibimahhmainimjh">
                <img src={linkedin_icon} alt="..." />
              </div>
              <p className="faccevttxttpphhtxtpp">Linkedin</p>
            </div>
          </div>
        </div>
      </div>

      {editcsolisdmoidala && (
        <Editsocilamediamodal
          updateForm={updateForm}
          data={data}
          closemodal={editsoicilksaincclick}
        />
      )}
    </>
  );
};

export default Socilallinkprofile;
