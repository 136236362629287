import React, { useEffect, useState } from "react";
import giftcard from "../../Images/gift-icon.png";
import img1 from "../../Images/cover img.jpg";
import img2 from "../../Images/cover img.jpg";
import img3 from "../../Images/cover img.jpg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  OwnWalletDetails,
  SingleOurPartNerAllOffer,
  congratsModalView,
} from "../../Redux/Slice/Offer";
import { getOwnProfile } from "../../Redux/Slice/PostSlice";
import AddvoucherWalletModal from "../../Modal/AddvoucherWalletModal";
import CongratsModal from "../../Modal/CongratsModal";
import HttpClient from "../../utils/HttpClient";

function OffersCategoriesDetails() {
  const [modalD, setModalD] = useState(false);
  const [modalF, setModalF] = useState(false);
  const [OfferSingleIDforPurchase, setOfferSingleIDforPurchase] = useState("");
  const params = useParams();
  const dispatch = useDispatch();
  const { SingleOurPartNerData } = useSelector((state) => state.offer);
  const { UserProfileID } = useSelector((state) => state.app);

  //offer view states start

  const { congratsModalStatus, WalletData } = useSelector(
    (state) => state.offer
  );
  const nonPurchaseWalletOffer = WalletData?.filter(
    (item) => !item?.voucherReedem
  );
  console.log(congratsModalStatus, "congratsModalStatus");

  //offer view states end
  console.log(UserProfileID?.walletPoints?.points, "params");
  console.log(SingleOurPartNerData, "SingleOurPartNerData");
  console.log(OfferSingleIDforPurchase, "offerid");
  console.log(params, "params");
  console.log(nonPurchaseWalletOffer, "nonPurchaseWalletOffer");
  const cardbox = [
    {
      id: 1,
      src: img1,
      text: "0% Interest Payment Plan for 6 months on a minimum transaction of AED 1,000 when you use your Credit Card at any Dynatrade Auto Service Outlets across UAE.",
    },
    {
      id: 2,
      src: img2,
      text: "0% Interest Payment Plan for 6 months on a minimum transaction of AED 1,000 when you use your Credit Card at any Dynatrade Auto Service Outlets across UAE.",
    },
    {
      id: 3,
      src: img3,
      text: "0% Interest Payment Plan for 6 months on a minimum transaction of AED 1,000 when you use your Credit Card at any Dynatrade Auto Service Outlets across UAE.",
    },
  ];

  const onclick2 = () => {
    setModalD(false);

    dispatch(congratsModalView(true));
  };
  const handleClick = (data) => {
    // console.log("sdf54sd", id,id._id)
    setOfferSingleIDforPurchase(data);
    setModalD(!modalD);
  };
  // this will when ever user views this perticular offer
  const offerViewWhenUserVisitsThisPage = async () => {
    const data = {
      offerId: SingleOurPartNerData?.[0]?._id,
      partnerId: SingleOurPartNerData?.[0]?.partnerId,
    };
    const res = await HttpClient.requestData("offer-view", "POST", data);
    if (res && res?.status) {
      console.log(res?.message);
    } else {
      console.log(res?.message);
    }
  };
  useEffect(() => {
    dispatch(SingleOurPartNerAllOffer(params.id));
    dispatch(getOwnProfile());
  }, [params.id]);

  useEffect(() => {
    if (SingleOurPartNerData) {
      offerViewWhenUserVisitsThisPage();
    }
  }, [SingleOurPartNerData]);
  useEffect(() => {
    dispatch(OwnWalletDetails());
  }, []);

  return (
    <>
      <section className="OffersCategDetails_page">
        <div className="cust_container">
          <div className="top_area">
            <Link className="backbtn" to="/reward">
              <i className="fa-solid fa-arrow-left-long" />
            </Link>
            <h4 className="heading">Offers categories</h4>
            <Link to="/my-wallet">
              <div className="icon">
                <img src={giftcard} className="img-fluid" alt="....." />
                {nonPurchaseWalletOffer?.length > 0 && (
                  <span className="notification_count">
                    {nonPurchaseWalletOffer?.length}
                  </span>
                )}
              </div>
            </Link>
          </div>
          {SingleOurPartNerData?.map((item, index) => {
            const differencePoint =
              Number(UserProfileID?.walletPoints?.points) -
              Number(item?.reddemingPoints);

            return (
              <div className="card_wrap" key={index}>
                <div className="d-flex justify-content-start">
                  <h4 className="mx-2 mb-2">{item?.offerCatName}</h4>
                </div>
                <div className="img_wrap">
                  <img
                    src={item?.offerCoverImage}
                    className="img-fluid"
                    alt=".."
                  />
                </div>
                <div className="d-flex">
                  <span
                    style={{ fontSize: "20px", marginLeft: "12rem" }}
                    className="mb-2"
                  >
                    {item?.offerType}
                  </span>
                </div>
                <div className="description">
                  <p>{item?.description}</p>
                </div>

                <div className="count_area">
                  <p className="textbox">
                    <span>{UserProfileID?.walletPoints?.points}</span> Neoo
                    search points
                  </p>

                  <div className="Offerdetails_accordion">
                    <Accordion>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <p className="textbox">
                              <span>{item?.reddemingPoints}</span> Redeeming
                              points
                            </p>
                            <p className="name">Offer details</p>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          {/****inner start*/}
                          <div className="inner_accordion">
                            <Accordion>
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    How can you get this offer
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <p>{item?.howToGetOffer}</p>
                                  {/* <p>
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged. It was popularised
                                </p> */}
                                </AccordionItemPanel>
                              </AccordionItem>
                              <AccordionItem>
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    Terms and Conditions
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <p>{item?.offerTermsCondition}</p>
                                  {/* <p>
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged. It was popularised
                                </p> */}
                                </AccordionItemPanel>
                              </AccordionItem>
                            </Accordion>
                          </div>
                          {/****inner end*/}

                          <div className="bottom_part">
                            <a
                              href={item?.profileLink}
                              target="_blank"
                              className="ViewProfile_btn"
                            >
                              View Profile
                            </a>
                            <div className="d-flex align-items-center justify-content-end">
                              <div
                                onClick={() => handleClick(item)}
                                style={{ cursor: "pointer" }}
                                className="RedeemNow_btn"
                              >
                                Redeem Now
                              </div>
                              <span className="thikbtn">
                                {differencePoint < 0 ? (
                                  <i
                                    className="fa-solid fa-circle-check"
                                    style={{ color: "grey" }}
                                  ></i>
                                ) : (
                                  <i className="fa-solid fa-circle-check"></i>
                                )}
                              </span>
                            </div>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {modalD && (
        <AddvoucherWalletModal
          OfferSingleIDforPurchase={OfferSingleIDforPurchase}
          closemodal1={setModalD}
          onclick={() => {
            onclick2();
          }}
        />
      )}
      {congratsModalStatus && <CongratsModal closemodal2={setModalF} />}
    </>
  );
}

export default OffersCategoriesDetails;
