import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import { useSelector } from "react-redux";

const Chooseplan = () => {
  const { UserProfileID } = useSelector((state) => state.app);
  console.log("LINE07X", UserProfileID);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPlan = async () => {
    setLoading(true);
    const res = await HttpClient.requestData("subscription-plans", "GET", "");
    if (res && res.status) {
      setData(res?.data.reverse());
    } else {
      console.log("Error from getting subscription : ", res);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPlan();
  }, []);

  return (
    <>
      <section className="choseplan">
        <div className="choosplantxdivmain">
          <h4 className="choosplantxttpp">Choose Your Plan</h4>
          <p className="fromstartuptxttp">From Startup to Enterprise</p>
          <p className="pisckktxtpp">
            Pick an account plan that fits your marketing
          </p>
        </div>

        <div className="chooseplandivmaon">
          <div className="chooseplandivmaondivflx">
            {loading ? (
              <div className="loader">Loading plans...</div>
            ) : (
              data?.map((item, index) => {
                return (
                  <div
                    className="chooseplandivmaondivbrdr"
                    key={index}
                    style={
                      item.plan === "Gold" ? { backgroundColor: "#662D91" } : {}
                    }
                  >
                    <div className="chooseplandivmaondivbrdrmain">
                      <button
                        className="basiuicvaytbtu"
                        style={
                          item.plan === "Gold"
                            ? { color: "#FFF", borderColor: "#fff" }
                            : {}
                        }
                      >
                        {item?.plan}
                      </button>
                      <div className="">
                        <p
                          className="freesttxppp"
                          style={item.plan === "Gold" ? { color: "#FFF" } : {}}
                        >
                          {item?.type}
                        </p>
                        <p
                          className="auuhshtxttpp"
                          style={item.plan === "Gold" ? { color: "#FFF" } : {}}
                        >
                          {item?.validity}
                        </p>
                      </div>

                      <div className="baysyysttxtpppdsidnbmai">
                        <p
                          className="baysyysttxtppp"
                          style={item.plan === "Gold" ? { color: "#FFF" } : {}}
                        >
                          Basic listing
                        </p>
                        <ul className="busuyyasbasytxtxtppul">
                          {item?.listing?.map((it, index) => {
                            return (
                              <li
                                key={index}
                                className="busuyyasbasytxtxtppli"
                                style={
                                  item.plan === "Gold" ? { color: "#FFF" } : {}
                                }
                              >
                                {it}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      {
                        UserProfileID ? (
                          <>
                            <Link
                              className="choosttappanbtn"
                              to={`/member/new`}
                              state={{ choosePlanID: item?._id }}
                              // state={{ subscriptionId: item?._id }}
                              
                            >
                              Choose Plan
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link
                              className="choosttappanbtn"
                              to="/Searchloginform"
                              state={{ choosePlanID: item?._id }}
                            >
                              Choose Plan
                            </Link>
                          </>
                        )
                      }



                      <div className="Viewbtnmain">
                        <Link to="/" className="vieiimitrrebtn">
                          View more
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Chooseplan;
